<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader> Configuración de productos </CCardHeader>
        <CCardBody>
          <CRow v-for="item in form" :key="item.ItemCode">
            <CCol xs="12" md="4">
              <CInput
                label="Producto"
                placeholder="Producto"
                v-model="item.ItemCode"
                readonly
              />
            </CCol>
            <CCol xs="12" md="2">
              <CInput
                type="number"
                label="Comisión"
                placeholder="0.0"
                v-model="item.commission"
                required
              />
            </CCol>
            <CCol xs="12" md="2">
              <CInput
                type="number"
                label="Comisión sin factura"
                placeholder="0.0"
                v-model="item.commissionwi"
                required
              />
            </CCol>
            <CCol xs="12" md="2">
              <CInput
                type="number"
                label="Mínimo"
                placeholder="0"
                v-model="item.min"
                min="1"
                required
              />
            </CCol>
            <!-- <CCol xs="12" md="2">
              <CInput
                type="text"
                label="Deal Code"
                v-model="item.dealcode"
              />
            </CCol>
            <CCol xs="12" md="2">
              <CInput
                type="date"
                label="Expire date"
                v-model="item.expireDate"
              />
            </CCol> -->
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow class="mt-2">
            <CCol sm="12" md="6">
              <CButton color="primary" @click="goBack">Back</CButton>
            </CCol>
            <CCol sm="12" md="6" class="text-right">
              <CButton color="primary" @click="save" v-if="!loading"
                >Guardar</CButton
              >
              <div class="text-right" v-if="loading">
                <CSpinner variant="grow" size="lg" />
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>

    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import moment from "moment";

const resource = new Resource("config/products");

export default {
  name: "ConfigProduct",
  data() {
    return {
      loading: false,
      toasts: [],
      form: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async save() {
      this.loading = true;
      try {
        const response = await resource.store(this.form);
        if (response.status == 200 && response.data.status == "OK") {
          const data = response.data.data;
          this.form = data;
          this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Configuración actualizada con exito",
          });
          console.log(this.form);
        } else {
          throw response.data.message;
        }
      } catch (error) {
        console.log(error);
        this.toasts.push({
          title: "Error",
          color: "danger",
          message: error,
        });
      } finally {
        this.loading = false;
      }
    },

    async loadData() {
      this.loading = true;
      try {
        const response = await resource.list();
        if (response.status == 200) {
          const { data } = response.data;
          this.form = data;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
